import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
  },
  actions: {
    fetchForexTransactionsFilters(
      _,
      { getAllPromoters, allPortfolios, ValidateHasAccessAllPromoters } = {},
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/forex-transactions/get_filters', {
            params: {
              getAllPromoters,
              ValidateHasAccessAllPromoters,
            },
          })
          .then(response => {
            resolve(response.data)
            if (allPortfolios === false) {
              response.data.portfolioList.shift()
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchForexTransactions(
      _,
      {
        PortfolioCode,
        TraderCode,
        OpenDayDate,
        Origen,
        TransactionFolio,
        BankingArea,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/v1/forex_transaction/consulta_operaciones', {
            params: {
              PortfolioCode,
              TraderCode,
              OpenDayDate,
              Origen,
              TransactionFolio,
              BankingArea,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchForexTransactionDetails(_, { ForexTransactionId, PortfolioCode, IsCCy, BankingArea }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/v1/forex_transaction/detail', {
            params: {
              ForexTransactionId,
              PortfolioCode,
              IsCCy,
              BankingArea,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchForexTransactionInstructions(_, { forextransactionId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/payment_instruction/${forextransactionId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    createOperation(_, { payload }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post(
            '/v1/forex_transaction/captura_rapida',
            {
              ...payload,
            },
            {
              headers: {
                'x-logintude': '-20.9398383',
                'x-latitude': '101.9289282',
              },
            },
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    completeOperation(_, { payload }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post(
            '/v1/forex_transaction/complete_transaction',
            {
              ...payload,
            },
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    cancelOperation(_, { transactionNumber, comment }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post(
            `/forex-transactions/cancel-transaction/${transactionNumber}`,
            `"${comment}"`,
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    cancelConfirmOperation(_, { transactionNumber }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post(
            `/forex-transactions/${transactionNumber}/cancel_confirm_transaction`,
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    cancelPastDayOperation(_, { transactionNumber }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post(
            `/forex-transactions/cancel_past_day_transaction/${transactionNumber}`,
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateExchangeCostRate(_, { transactionNumber, value }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post(
            `/forex-transactions/update_exchange_cost_rate/${transactionNumber}`,
            value,
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getOperationLimits(
      _,
      {
        CustomerId, PortfolioId, TraderId, FxAmount, FxId, SpotOrForward, CurrencyId,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/forex-transactions/get_operation_limits', {
            params: {
              CustomerId,
              PortfolioId,
              TraderId,
              FxAmount,
              FxId,
              SpotOrForward,
              CurrencyId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getBuiltInstruction(
      _,
      {
        payeeSettlementTypeId,
        forexTransId,
        isBuying,
        amount,
        instructionListIndex,
        currencyId,
        currencyISOCode,
        productId,
        productName,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/forex-transactions/build_instruction', {
            params: {
              payeeSettlementTypeId,
              forexTransId,
              isBuying,
              amount,
              instructionListIndex,
              currencyId,
              currencyISOCode,
              productId,
              productName,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    downloadOperationPdf(_, { transactionNumber }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/forex-transactions/detail/${transactionNumber}.html`, {
            responseType: 'blob',
            filename: `operation-${transactionNumber}.html`,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchForexTransactionsToAuthorize(
      _,
      {
        portfolioid, TransactionDate, Origin, trader,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/forex-transactions/get_transactions_to_authorize', {
            params: {
              portfolioid,
              TransactionDate,
              Origin,
              trader,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchForexTransactionsToConfirm(
      _,
      { OperationDate, ForexTransType, ConfirmationStatus },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/forex-transactions/get_transactions_to_confirm', {
            params: {
              OperationDate,
              ForexTransType,
              ConfirmationStatus,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchForexTransactionsConfirmationLetter(_, { TransactionNumber, ContentType = 'html' }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/forex-transactions/get_confirmation_letter.${ContentType}`, {
            params: {
              TransactionNumber,
            },
            responseType: 'blob',
            filename: `confirmation_letter-${TransactionNumber}.html`,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    confirmForexTransaction(
      _,
      {
        forexTransId, employeeId, confirmationMethodId, comments, date,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/forex-transactions/confirm_transaction', {
            forexTransId,
            employeeId,
            confirmationMethodId,
            comments,
            date,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    authorizeForexTransaction(
      _,
      {
        TransactionNumber, TransAuthStatusid, Comments, reasonId,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post(
            '/forex-transactions/authorize_transaction',
            {},
            {
              params: {
                TransactionNumber,
                TransAuthStatusid,
                Comments,
                reasonId,
              },
            },
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    rejectForexTransaction(
      _,
      {
        TransactionNumber, TransAuthStatusid, Comments, reasonId,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post(
            '/forex-transactions/reject_transaction_to_authorize',
            {},
            {
              params: {
                TransactionNumber,
                TransAuthStatusid,
                Comments,
                reasonId,
              },
            },
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchRetailTransaction(_, { transactionDate, traderId, currencyId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/retail-transactions', {
            params: {
              transactionDate,
              traderId,
              currencyId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchTransactionsToCancelPreviousDays(
      _,
      { transactionNumber, dateToGet, portfolioId },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/forex-transactions/get_transactions_to_cancel_previous_days', {
            params: {
              transactionNumber,
              dateToGet,
              portfolioId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchMonitorTransactionsWeb(_, { TransactionDate, TraderId, CurrencyId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/forex-transactions/get_monitor_transactions_web', {
            params: {
              TransactionDate,
              TraderId,
              CurrencyId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveInterbankOperation(_, { transaction }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/forex-transactions/create_interbank', transaction)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
