// eslint-disable-next-line import/no-cycle
import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBeneficiaries(_, { customerCode, bankingArea }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/v1/beneficiaries/get_beneficiaries_by_customer_code', {
            params: {
              customerCode,
              bankingArea,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveBeneficiaries(_, payload) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/v1/beneficiaries', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
