import VueI18n from '@/libs/i18n/index'

// render file once fetching from API
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

// Get user web client to adapt functionality like scrolling onto widgets
export function browser() {
  if (
    (navigator.userAgent.indexOf('Opera')
      || navigator.userAgent.indexOf('OPR')) !== -1
  ) {
    return 'Opera'
  }
  if (navigator.userAgent.indexOf('Edg') !== -1) {
    return 'Edge'
  }
  if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome'
  }
  if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari'
  }
  if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox'
  }
  return 'unknown'
}

// Get char based on its index
export function matchIndex(match, values) {
  values = Array.from(values)
  const matchedIndex = []
  let idx = values.indexOf(match)
  while (idx !== -1) {
    matchedIndex.push(idx)
    idx = values.indexOf(match, idx + 1)
  }
  return matchedIndex
}

// get numbers inside a text string
export function getNumbers(value) {
  const number = value.replace(/[^\d|.-]/g, '').replace(/(?!^)-/g, '').replace(/(\.){2,}/g, '$1')
  return number
}

// merge array
export function insertAt(array, index, ...elementsArray) {
  array.splice(index, 0, ...elementsArray)
}

// parse text to generate proper URL route
export function slugify(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\u0100-\uFFFF\w-]/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

// Function recives a string to valide if is the same e.g. USDUSD, EUREUR
export function isMirrorValue(value) {
  const isMirror = value.text.slice(0, 3) === value.text.slice(3)
  return isMirror
}

export function getModelValues(model) {
  return Object.entries(model).reduce((acc, [key, { value }]) => {
    acc[key] = value
    return acc
  }, {})
}

export function handleSwaggerValidationErrorMessages(errors, refs) {
  function handleErrorMessages(error) {
    let errorMsg = 'error'
    function constraintsViolation() {
      switch (error.constraintName) {
        case 'minLength':
          errorMsg = `${VueI18n.t('minError')} ${error.constraintValue} ${VueI18n.t('characters')}`
          break

        case 'maxLength':
          errorMsg = `${VueI18n.t('maxError')} ${error.constraintValue} ${VueI18n.t('characters')}`
          break

        case 'maximum':
          errorMsg = `${VueI18n.t('maxValError')} ${error.constraintValue}`
          break

        case 'minimum':
          errorMsg = `${VueI18n.t('minValError')} ${error.constraintValue}`
          break

        case 'pattern':
          errorMsg = VueI18n.t('validation.pattern')
          break

        default:
          errorMsg = `${VueI18n.t('errorMsg')} ${error.constraintName}`
          break
      }
    }

    function typeMismatch() {
      errorMsg = `${VueI18n.t('theValIs')} ${error.typeIs} ${VueI18n.t('hasToBe')} ${error.typeShouldBe}`
    }

    function dateFormat() {
      errorMsg = VueI18n.t('validation.dateFormat')
    }

    function missingRequiredProperty() {
      errorMsg = VueI18n.t('validation.required')
    }

    switch (error.errorType) {
      case 'CONSTRAINTS_VIOLATION':
        constraintsViolation()
        break
      case 'TYPE_MISMATCH':
        typeMismatch()
        break
      case 'DATE_FORMAT':
        dateFormat()
        break
      case 'MISSING_REQUIRED_PROPERTY':
        missingRequiredProperty()
        break

      default:
        break
    }

    return errorMsg
  }

  errors.forEach(error => {
    const errorCopy = { ...error }
    // Remove first element of error array beacause stepname is always root
    errorCopy.trace.shift()
    // Join stepnames with a slash at the end
    let errorId = ''
    errorCopy.trace.forEach(el => {
      errorId += `${el.stepName}/`
    })
    // Remove the las slash
    errorId = errorId.slice(0, -1)
    const ref = refs[`${errorId}-input`]
    const errorRef = refs[`${errorId}-error`]
    if (ref) {
      // if there is an error, add error classes and custom error depending the case
      ref.$el.classList.add('is-invalid')
      errorRef.classList.value = 'd-flex text-danger'
      errorRef.innerText = handleErrorMessages(error)
    }
  })
}

export const sortRemote = orderBy => {
  if (!orderBy) return ''
  const response = Object.entries(orderBy).map(([clave, valor]) => (`OrderBy[${clave}]=${valor}`)).join('&')
  return response
}

export const calculatePenalty = interval => {
  if (interval.spreadDivisor > 0 && interval.transactionTypeSpread > 0) {
    // Para calcular el castigo se usa: (TransactionTypeSpread / SpreadDivisor)\
    interval.intervalSpread = +interval.transactionTypeSpread / +interval.spreadDivisor
  }
}

export const sortTable = (sortBy, sortDesc, items) => {
  items.sort((a, b) => {
    const propA = a[sortBy]
    const propB = b[sortBy]
    const isNumber = typeof propA === 'number' && typeof propA === 'number'
    if (isNumber) {
      if (!sortDesc) {
        return propA - propB
      }
      return propB - propA
    }
    if (!sortDesc) {
      return String(propA).localeCompare(String(propB))
    }
    return String(propB).localeCompare(String(propA))
  })
}

export const getCurrentDate = () => {
  const currentDate = new Date()
  const dayOfMonth = currentDate.getDate()
  const month = currentDate.getMonth() + 1
  const year = currentDate.getFullYear()
  const currentDateText = `${year}-${month < 10 ? '0' : ''}${month}-${dayOfMonth < 10 ? '0' : ''}${dayOfMonth}`
  return currentDateText
}

export const getformattedDate = inputDate => {
  const date = new Date(inputDate)
  const day = date.getUTCDate().toString().padStart(2, '0')
  const monthIndex = date.getUTCMonth()
  const year = date.getUTCFullYear().toString().slice(-2)

  const months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']
  const month = months[monthIndex]
  const formattedDate = `${day} ${month} ${year}`
  return formattedDate
}

export const getEnvVariable = (key, defaultValue) => {
  return window._env_ && window._env_[key] ? window._env_[key] : defaultValue
}

export const setSelectOptions = (options, text, value) => {
  const setOptions = options.map(e => ({
    text: e[text].trim(),
    value: e[value].trim(),
  }))
  return setOptions
}

export const toUpperCase = value => value.toUpperCase()

export default {
  getBase64,
  browser,
  matchIndex,
  getNumbers,
  insertAt,
  slugify,
  getCurrentDate,
  getEnvVariable,
  setSelectOptions,
  toUpperCase,
}
